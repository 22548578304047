import React, { useEffect, useState } from "react"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { ErrorComponent } from "../../components/Utils/ErrorComponent"
import { navigate } from "gatsby-link"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { loginRequest, msalConfig } from "../../components/Security/authConfig"
import { StringParam, useQueryParam } from "use-query-params"
import { GetMembership } from "../../components/Utils/ReactQueries"
import { authToken } from "../../components/Security/authToken"
import { right, saveButton } from "../../components/Styling/Buttons"

const magasininn = () => {
  const queryClient = useQueryClient()
  const [auth, setAuth] = useQueryParam("auth", StringParam)
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const membership = GetMembership(accounts, inProgress, instance)

  const authRequest = {
    ...loginRequest,
    clientId: process.env.GATSBY_APP_AAD_APPLICATION_ID,
    authority:
      process.env.GATSBY_APP_AAD_B2C_INSTANCE +
      process.env.GATSBY_APP_AAD_B2C_TENTANT +
      "/" +
      process.env.GATSBY_APP_AAD_B2C_SIGNINGPOLICY,
    validateAuthority: false,
    knownAuthorities: [process.env.GATSBY_APP_AAD_B2C_KNOWN_AUTHORITIES],
    redirectUri: process.env.GATSBY_APP_BASE_URL + "magasininn",

    extraQueryParameters: {
      option: auth,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  }
  useEffect(() => {
    const token = authToken(accounts, inProgress, instance)
    if (isAuthenticated) {
      if (
        accounts[0]?.idTokenClaims["idp"] != "Vipps" &&
        accounts[0]?.idTokenClaims["idp"] != "BankId"
      ) {
        navigate("/verifisering")
      }
    }
  }, [isAuthenticated])
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
    >
      <QueryClientProvider client={queryClient}>
        <div className="lg:flex lg:justify-center md:flex md:justify-center">
          <div>
            <h1 className="text-xl font-sans text-blue mb-12 mt-4">
              Snart ferdig
            </h1>
            <div className="lg:w-full">
              <div>
                <p className="font-sans mb-0">
                  Ved å trykke på knappen, bekrefter du at du ønsker å bli
                  medlem.
                </p>
              </div>
              <div className="pt-8">
                <div>
                  <button
                    className={saveButton + right}
                    onClick={() => navigate("/magasininn/mine-opplysninger")}
                  >
                    Bli medlem
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}
export default magasininn
